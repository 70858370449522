// Generated by Framer (7f988a2)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["pjWlzKYnl", "AhUAVmWv9"];

const serializationHash = "framer-RRt60"

const variantClassNames = {AhUAVmWv9: "framer-v-1gm5lzb", pjWlzKYnl: "framer-v-po6jck"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Default: "pjWlzKYnl", White: "AhUAVmWv9"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "pjWlzKYnl"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "pjWlzKYnl", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "AhUAVmWv9") return true
return false
}

const isDisplayed1 = () => {
if (baseVariant === "AhUAVmWv9") return false
return true
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={{webPageId: "augiA20Il"}} openInNewTab={false}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-po6jck", className, classNames)} framer-q0arz5`} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"pjWlzKYnl"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({AhUAVmWv9: {"data-framer-name": "White"}}, baseVariant, gestureVariant)}>{isDisplayed() && (<Image background={{alt: "", fit: "fit", pixelHeight: 173, pixelWidth: 400, positionX: "center", positionY: "center", src: "https://framerusercontent.com/images/EzVZt8TUf2gm7IjSyp7Z8ZqcT0.png"}} className={"framer-19bikx9"} layoutDependency={layoutDependency} layoutId={"dRdQWSql7"}/>)}{isDisplayed1() && (<Image background={{alt: "", fit: "fit", pixelHeight: 173, pixelWidth: 400, positionX: "center", positionY: "center", src: "https://framerusercontent.com/images/EzVZt8TUf2gm7IjSyp7Z8ZqcT0.png"}} className={"framer-1hum5we"} layoutDependency={layoutDependency} layoutId={"RUQkEtc8o"}/>)}</motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-RRt60.framer-q0arz5, .framer-RRt60 .framer-q0arz5 { display: block; }", ".framer-RRt60.framer-po6jck { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: 50px; justify-content: flex-start; overflow: hidden; padding: 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-RRt60 .framer-19bikx9, .framer-RRt60 .framer-1hum5we { flex: none; height: 50px; position: relative; width: 150px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-RRt60.framer-po6jck { gap: 0px; } .framer-RRt60.framer-po6jck > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-RRt60.framer-po6jck > :first-child { margin-left: 0px; } .framer-RRt60.framer-po6jck > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 50
 * @framerIntrinsicWidth 150
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"AhUAVmWv9":{"layout":["auto","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerV5nGfGfGC: React.ComponentType<Props> = withCSS(Component, css, "framer-RRt60") as typeof Component;
export default FramerV5nGfGfGC;

FramerV5nGfGfGC.displayName = "Logo";

FramerV5nGfGfGC.defaultProps = {height: 50, width: 150};

addPropertyControls(FramerV5nGfGfGC, {variant: {options: ["pjWlzKYnl", "AhUAVmWv9"], optionTitles: ["Default", "White"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerV5nGfGfGC, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})